
import ProductCard from '@/components/Products/ProductCardV2.vue'

import { mapGetters } from 'vuex'
import { convertToSlug } from '~/helpers/generate-slug'
export default {
  components: { ProductCard },
  data() {
    const vm = this
    return {
      activeIndex: 0,
      st: this.$store.state.settings.contents[this.$i18n.locale],
      swiperOption: {
        autoplay: 4500,
        spaceBetween: 20,
        slidesPerView: 3,
        loop: false,
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        },
        onSlideChangeEnd: (swiper) => {
          // can do something
          if (vm) vm.activeIndex = swiper.activeIndex
        },
      },
    }
  },
  methods: {
    convertToSlug,
  },
  computed: {
    ...mapGetters({
      getBlogs: 'blogs/getBlogs',
    }),
  },
}
